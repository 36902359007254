import debounce from 'lodash.debounce';
import { useCallback, useContext, useEffect, useState } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { DeliveryDahsboardPageContext } from '../../context/DeliveryDahsboardPageContext';
import AmountCard from './components/AmountCard';
import { DeliveryOverviewData } from '@nexploretechnology/concreting-core-client/concrete/delivery.note.lite-client/delivery.note.lite.dto';

interface DeliveryDashboardAmountProps {
    refreshData: boolean;
}

export function DeliveryDashboardAmount({ refreshData }: DeliveryDashboardAmountProps) {
    const { filter } = useContext(DeliveryDahsboardPageContext);
    const [data, setData] = useState<DeliveryOverviewData>();
    const api = useApi();
    const { projectId, companyId } = useProjectState();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadDeliveryOverview = useCallback(
        debounce(() => {
            api.deliveryNoteLiteClient
                .loadDeliveryOverviewData(companyId, projectId, filter)
                .then((response) => {
                    if (response.isSuccess()) {
                        setData(response.getEntity());
                    } else {
                        const msg = JSON.parse(response.getError());
                        ErrorNotification({ message: msg.error?.message ?? msg.message, description: '' });
                    }
                })
                .catch((info) => {
                    ErrorNotification({
                        message: info.errorFields[0].errors[0],
                        description: ''
                    });
                });
        }, 1000),
        [filter]
    );

    useEffect(() => {
        loadDeliveryOverview();
    }, [filter, refreshData, loadDeliveryOverview]);

    if (!data) {
        return null;
    }

    return <AmountCard data={data} />;
}

/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */

import { t } from '@lingui/macro';
import { Select } from 'antd';
import { useEffect } from 'react';
import useGetDeliveryLocationDescriptionByProject from 'src/app-react/business-page/shared/Modals/OrderLiteModal/hooks/useGetDeliveryLocationDescriptionByProject';
import { InputFormItemProps } from 'src/app-react/components/Form/FormItem/InputFormItem';
import SelectFormItem from 'src/app-react/components/Form/FormItem/SelectFormItem';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';

function LocationDescriptionFormItem(props: InputFormItemProps) {
    const { loadData, locationDescriptions, error } = useGetDeliveryLocationDescriptionByProject();

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        if (error) {
            ErrorNotification({
                message: error.message || 'unexpected error',
                description: error.description
            });
        }
    }, [error]);

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <SelectFormItem label={t`Location Description`} name="locationDescription" {...props}>
            <Select
                placeholder={t`Please select a description`}
                allowClear
                options={locationDescriptions}
                data-testid="locationDescriptionFormItem"
            />
        </SelectFormItem>
    );
}

export default LocationDescriptionFormItem;

/* eslint-disable react/require-default-props */

import { t } from '@lingui/macro';
import { UnloadingLocationBP } from '@nexploretechnology/concreting-core-client/concrete/unloading.location-client/unloading.location.dto';
import { SimpleClientResponse } from '@nexploretechnology/concreting-core-client/simple.client.response';
import { Button, Col, Form, Modal, Row } from 'antd';
import LocationDescriptionFormItem from 'src/app-react/business-page/shared/FormItems/Location/LocationDescriptionFormItem';
import LocationNameFormItem from 'src/app-react/business-page/shared/FormItems/Location/LocationNameFormItem';
import LocationTypeFormItem from 'src/app-react/business-page/shared/FormItems/Location/LocationTypeFormItem';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import useOrderDependenciesState from '../../hooks/useOrderDependenciesState';

interface CreateUnloadingLocationModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onComplete?: (unloadingLocation: string) => void;
}

interface UnloadingLocationForm {
    locationName: string;
    locationType: string;
    locationDescription: string;
}

function UnloadingLocationModal(props: CreateUnloadingLocationModalProps) {
    const {
        validations: { locationNameRules, locationDescriptionRules }
    } = useOrderDependenciesState();
    const { open, setOpen, onComplete } = props;
    const [createUnloadingLocationForm] = Form.useForm();
    const { projectId, companyId } = useProjectState();
    const api = useApi();

    const createUnloadingLocation = async (unloadingLocation: UnloadingLocationBP) => {
        console.log(unloadingLocation);
        await api.unloadingLocationClient
            .createUnloadingLocation(companyId, projectId, unloadingLocation)
            .then((response: SimpleClientResponse<{ id: string }>) => {
                if (response.isSuccess()) {
                    SuccessNotification({
                        message: t`Unloading location created successfully`,
                        description: ''
                    });

                    setOpen(false);
                    createUnloadingLocationForm.resetFields();
                    onComplete?.(response.getEntity().id);
                } else {
                    const errorCode = (response.getWrappedResponse().data as any)?.error?.code;
                    const error =
                        errorCode === 'ERROR_UNLOADING_LOCATION_DUPLICATE'
                            ? t`Location already exists`
                            : t`unexpected error`;

                    ErrorNotification({ message: t`Creating UnloadingLocation error`, description: error });
                }
            })
            .catch((e) => {
                ErrorNotification({ message: e.code, description: e.message });
            });
    };

    const onSubmit = async (): Promise<void> => {
        try {
            const { locationName, locationType, locationDescription }: UnloadingLocationForm =
                await createUnloadingLocationForm.validateFields();

            const unloadingLocation: UnloadingLocationBP = {
                companyId,
                projectId,
                locationType,
                locationName,
                locationDescription
            };

            createUnloadingLocation(unloadingLocation);
        } catch (error) {
            ErrorNotification({
                message: t`Form not filled correctly`,
                description: t`Please, make sure that all the fields are filled correctly`
            });
        }
    };

    const onCancel = (): void => {
        createUnloadingLocationForm.resetFields();
        setOpen(false);
    };

    return (
        <Modal
            maskClosable={false}
            open={open}
            onCancel={onCancel}
            title={t`Create a new location`}
            width={550}
            data-testid="unloadingLocationModal"
            footer={[
                <Button data-testid="sendButton" key="send" onClick={onSubmit} type="primary">
                    {t`Create`}
                </Button>
            ]}>
            <Form
                layout="vertical"
                form={createUnloadingLocationForm}
                data-testid="CreateUnloadingLocationForm"
                initialValues={{}}>
                <Row gutter={[8, 16]}>
                    <Col span={24}>
                        <LocationNameFormItem rules={locationNameRules} />
                    </Col>
                </Row>
                <Row gutter={[48, 16]}>
                    <Col span={24}>
                        <LocationTypeFormItem />
                    </Col>
                </Row>
                <Row gutter={[48, 16]}>
                    <Col span={24}>
                        <LocationDescriptionFormItem rules={locationDescriptionRules} />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default UnloadingLocationModal;

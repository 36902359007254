/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */

import { t, Trans } from '@lingui/macro';
import TextArea from 'antd/lib/input/TextArea';
import InputFormItem, { InputFormItemProps } from 'src/app-react/components/Form/FormItem/InputFormItem';

function LocationDescriptionFormItem(props: InputFormItemProps) {
    return (
        <InputFormItem
            data-testid="locationDescriptionFormItem"
            label={<Trans>Location Description</Trans>}
            name="locationDescription"
            required
            {...props}>
            <TextArea data-testid="locationDescription" placeholder={t`Enter a description`} />
        </InputFormItem>
    );
}

export default LocationDescriptionFormItem;

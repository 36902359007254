import { DeliveryStatusFilter } from '@nexploretechnology/concreting-core-client';
import React from 'react';

export type DeliveryDashboardFilter = DeliveryStatusFilter & { period: string };

interface DeliveryDahsboardPageContextValue {
    filter?: DeliveryDashboardFilter;
    setFilter: React.Dispatch<React.SetStateAction<DeliveryDashboardFilter>>;
}

export const DeliveryDahsboardPageContext = React.createContext<DeliveryDahsboardPageContextValue>(null);

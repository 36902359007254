import { Col, Row } from 'antd';
import { useContext } from 'react';
import { MARKETS } from 'src/app-react/constants/markets';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { ConcreteTypeInputItem } from './InputItems/ConcreteTypeInputItem';
import { CustomPeriodInputItem } from './InputItems/CustomPeriodInputItem';
import { DeliveryNoteNumberInputItem } from './InputItems/DeliveryNoteNumberInputItem';
import { PeriodConcretingInputItem } from './InputItems/PeriodConcretingInputItem';
import { SupplierOrderIdInputItem } from './InputItems/SupplierOrderIdInputItem';
import { UnloadingLocationInputItem } from './InputItems/UnloadingLocationInputItem';
import s from './style.module.css';
import { UnloadingLocationNameInputItem } from './InputItems/UnloadingLocationNameInputItem';
import { UnloadingLocationDescriptionInputItem } from './InputItems/UnloadingLocationDescriptionInputItem';
import { ResetButtonItem } from './InputItems/ResetButtonItem';
import { DeliveryDahsboardPageContext } from '../../context/DeliveryDahsboardPageContext';

export function DeliveryDashboardFilters() {
    const { marketId } = useProjectState();
    const { filter } = useContext(DeliveryDahsboardPageContext);

    return (
        <Row gutter={24} className={s.row}>
            <Col
                span={5}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '.5rem',
                    minWidth: '295px',
                    maxWidth: '600px'
                }}>
                <SupplierOrderIdInputItem />
            </Col>
            <Col
                span={5}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '.5rem',
                    minWidth: '295px',
                    maxWidth: '600px'
                }}>
                <ConcreteTypeInputItem />
            </Col>
            <Col
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '.5rem',
                    minWidth: '295px',
                    maxWidth: '600px'
                }}>
                <DeliveryNoteNumberInputItem />
            </Col>
            <Col
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '.5rem',
                    minWidth: '295px',
                    maxWidth: '600px'
                }}
                hidden={marketId === MARKETS.AUS}>
                <UnloadingLocationInputItem />
            </Col>
            <Col
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '.5rem',
                    minWidth: '295px',
                    maxWidth: '600px'
                }}
                hidden={marketId !== MARKETS.AUS}>
                <UnloadingLocationNameInputItem />
            </Col>
            <Col
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '.5rem',
                    minWidth: '295px',
                    maxWidth: '600px'
                }}
                hidden={marketId !== MARKETS.AUS}>
                <UnloadingLocationDescriptionInputItem />
            </Col>
            <Col style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                <PeriodConcretingInputItem />
            </Col>
            {filter?.period === 'custom' && (
                <Col style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                    <CustomPeriodInputItem />
                </Col>
            )}
            <Col style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                <ResetButtonItem />
            </Col>
        </Row>
    );
}

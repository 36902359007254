import { Trans } from '@lingui/macro';
import { Button, Row, Col } from 'antd';
import { useContext } from 'react';
import { DeliveryDahsboardPageContext } from '../../../context/DeliveryDahsboardPageContext';
import { UndoOutlined } from '@ant-design/icons';
import useLocalStorage from 'src/app-react/hooks/useLocalStorage';
import moment from 'moment';

export function ResetButtonItem() {
    const { filter, setFilter } = useContext(DeliveryDahsboardPageContext);
    const [localStorageDeliveryFilter, setLocalStorageDeliveryFilter] = useLocalStorage('deliveryFilter');

    function resetClick() {
        const deliveryDashboardDefaultFilter = {
            period: 'today',
            periodOfConcreting: {
                startDate: moment().startOf('day').toDate(),
                endDate: moment().endOf('day').toDate()
            }
        };

        setFilter(deliveryDashboardDefaultFilter);
        setLocalStorageDeliveryFilter(deliveryDashboardDefaultFilter);
    }

    function resetButtonHidden() {
        return !(
            filter?.concreteTypeNumbers?.length ||
            filter?.deliveryNumbers?.length ||
            filter?.supplierOrderIds?.length ||
            filter?.period !== 'today' ||
            filter?.unloadingLocationDescriptions?.length ||
            filter?.unloadingLocationNames?.length
        );
    }

    return (
        <>
            <br />
            <Row>
                <Col>
                    <Button
                        value="reset"
                        type="link"
                        size="small"
                        hidden={resetButtonHidden()}
                        data-testid="period-concreting-reset-btn"
                        onClick={resetClick}
                        style={{ fontWeight: 'lighter' }}>
                        <UndoOutlined />
                        <Trans>Reset</Trans>
                    </Button>
                </Col>
            </Row>
        </>
    );
}

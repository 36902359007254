import { Trans } from '@lingui/macro';
import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { useContext } from 'react';
import { DeliveryDahsboardPageContext } from '../../../context/DeliveryDahsboardPageContext';
import moment from 'moment';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';
import useLocalStorage from 'src/app-react/hooks/useLocalStorage';

const { RangePicker } = DatePicker;

export function CustomPeriodInputItem() {
    const { filter, setFilter } = useContext(DeliveryDahsboardPageContext);
    const [localStorageDeliveryFilter, setLocalStorageDeliveryFilter] = useLocalStorage('deliveryFilter');
    const { formats } = useProjectConstantsState();

    function onChange(value: RangePickerProps['value']) {
        if (value && value[0] && value[1]) {
            const startDate = value[0].startOf('day').toDate();
            const endDate = value[1].endOf('day').toDate();
            const newFilter = { ...filter, periodOfConcreting: { startDate, endDate } };
            setFilter(newFilter);
            setLocalStorageDeliveryFilter(newFilter);
        }
    }

    const startDate = moment(filter?.periodOfConcreting?.startDate);
    const endDate = moment(filter?.periodOfConcreting?.endDate);
    return (
        <>
            <b>
                <Trans>Custom period of concreting</Trans>
            </b>

            <RangePicker
                // eslint-disable-next-line react/jsx-no-bind
                onChange={onChange}
                value={[startDate, endDate]}
                format={formats.dateForm}
            />
        </>
    );
}

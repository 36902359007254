/* eslint-disable default-case */
import { t, Trans } from '@lingui/macro';
import { Alert, Button, Modal, Table, Tag } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';
import useProjectState from 'src/app-react/hooks/useProjectState';
import * as Service from '../../utils/concrete-tests-service';
import AirContentTestModal from '../AirContentTestModal/AirContentTestModal';
import ConsistencyTestModal from '../ConsistencyTestModal/ConsistencyTestModal';
import RelativeHumidityTestModal from '../RelativeHumidityTestModal/RelativeHumidityTestModal';
import TemperatureTestModal from '../TemperatureTestModal/TemperatureTestModal';
import WindSpeedTestModal from '../WindSpeedTestModal/WindSpeedTestModal';
import { freshConcreteTableColumns } from './FreshConcreteTestsTable.columns';
import { FreshConcreteTest } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/fresh.concrete.test.dto';
import { DeliveryNoteManagementContext } from '../../providers/delivery.note.provider';

export interface IFreshConcreteTestsTableProps {
    orderId: string;
    concreteLoadId: string;

    refreshTable?: boolean;
    setRefreshTable?: (value: boolean) => void;
    setFreshConcreteTestsCount?: (count: number) => void;
    records?: FreshConcreteTest[];
}

function FreshConcreteTestsTable(props: IFreshConcreteTestsTableProps) {
    const { orderId, concreteLoadId, refreshTable, setRefreshTable, setFreshConcreteTestsCount, records } = props;
    const { reloadDeliveryNotePage } = useContext(DeliveryNoteManagementContext);
    const { projectId, marketId, companyId } = useProjectState();
    const { units } = useProjectConstantsState();

    const [tableData, setTableData] = useState<FreshConcreteTest[]>();
    const api = useApi();

    const enableReloadTableDataTimer = useRef(true);
    const [isTemperatureTestModalVisible, setIsTemperatureTestModalVisible] = useState(false);
    const [isConsistencyTestModalVisible, setIsConsistencyTestModalVisible] = useState(false);
    const [isAirContentTestModalVisible, setIsAirContentTestModalVisible] = useState(false);
    const [isWindSpeedTestModalVisible, setIsWindSpeedTestModalVisible] = useState(false);
    const [isRelativeHumidityTestModalVisible, setIsRelativeHumidityTestModalVisible] = useState(false);

    const [isPopupConfirmModalVisible, setIsPopupConfirmModalVisible] = useState(false);

    const [contextData, setContextData] = useState<any>();
    const [popupConfirmModalData, setPopupConfirmModalData] = useState<any>();
    const [warningEvaporationMessage, setWarningEvaporationMessage] = useState(false);

    const reloadTableData = async (records?: FreshConcreteTest[]): Promise<void> => {
        let freshConcreteTest: FreshConcreteTest[];
        if (records?.length) {
            setTableData(records);
            setFreshConcreteTestsCount?.(records.length);
            return;
        }
        api.freshConcreteClient
            .loadFreshConcreteTestList(projectId, orderId, concreteLoadId, companyId)
            .then((response) => {
                if (response.isSuccess()) {
                    freshConcreteTest = response.getEntity();

                    setTableData(freshConcreteTest);
                    setFreshConcreteTestsCount?.(freshConcreteTest.length);
                } else {
                    ErrorNotification({
                        message: response.getError(),
                        description: ''
                    });
                }
            });
    };

    useEffect(() => {
        // first load
        reloadTableData(records);

        // interval to load at 20000 milliseconds
        const interval = setInterval(() => {
            if (enableReloadTableDataTimer.current) {
                reloadTableData();
            }
        }, 20000);

        return () => clearInterval(interval);
    }, []);

    function showModal() {
        switch (determingTestingType(contextData)) {
            case Service.ConcreteTestingTypes.Temperature:
                setIsTemperatureTestModalVisible(true);
                enableReloadTableDataTimer.current = false;
                break;
            case Service.ConcreteTestingTypes.Consistency:
                setIsConsistencyTestModalVisible(true);
                enableReloadTableDataTimer.current = false;
                break;
            case Service.ConcreteTestingTypes.AirContent:
                setIsAirContentTestModalVisible(true);
                enableReloadTableDataTimer.current = false;
                break;
            case Service.ConcreteTestingTypes.WindSpeed:
                setIsWindSpeedTestModalVisible(true);
                enableReloadTableDataTimer.current = false;
                break;
            case Service.ConcreteTestingTypes.RelativeHumidity:
                setIsRelativeHumidityTestModalVisible(true);
                enableReloadTableDataTimer.current = false;
        }
    }

    useEffect(() => {
        if (contextData) {
            showModal();
        }
    }, [contextData]);

    useEffect(() => {
        if (refreshTable) {
            reloadTableData(records);
            setRefreshTable?.(false);
        }
    }, [refreshTable]);

    const onEdit = (freshConcreteTest: FreshConcreteTest): void => {
        function isSameContextDataAsBefore(): boolean {
            return JSON.stringify(freshConcreteTest) === JSON.stringify(contextData);
        }

        if (isSameContextDataAsBefore()) {
            showModal();
        }

        setContextData(freshConcreteTest);
    };

    const onDelete = (freshConcreteTest: FreshConcreteTest): void => {
        setPopupConfirmModalData(freshConcreteTest);
        setIsPopupConfirmModalVisible(true);
        setWarningEvaporationMessage(wouldDeleteEvaporationRate(freshConcreteTest, tableData));
    };

    const onDetails = (freshConcreteTest: FreshConcreteTest): void => {
        // to be done in the future
    };

    const popupConfirmModalOnOk = async (): Promise<void> => {
        setIsPopupConfirmModalVisible(false);
        switch (determingTestingType(popupConfirmModalData)) {
            case Service.ConcreteTestingTypes.Temperature:
                api.freshConcreteClient
                    .deleteTemperatureTest(projectId, orderId, concreteLoadId, popupConfirmModalData.id, companyId)
                    .then((response) => {
                        if (response.isSuccess()) {
                            SuccessNotification({
                                message: t`Temperature test deleted successfully`,
                                description: ''
                            });
                            reloadTableData();
                            reloadDeliveryNotePage();
                        } else {
                            ErrorNotification({
                                message: t`Temperature test could not be deleted`,
                                description: ''
                            });
                        }
                    })

                    .catch((info) => {
                        ErrorNotification({
                            message: info.errorFields[0].errors[0],
                            description: ''
                        });
                    });
                break;
            case Service.ConcreteTestingTypes.Consistency:
                api.freshConcreteClient
                    .deleteConsistencyTest(projectId, orderId, concreteLoadId, popupConfirmModalData.id, companyId)
                    .then((response) => {
                        if (response.isSuccess()) {
                            SuccessNotification({
                                message: t`Consistency test deleted successfully`,
                                description: ''
                            });
                            reloadTableData();
                            reloadDeliveryNotePage();
                        } else {
                            ErrorNotification({
                                message: t`Consistency test could not be deleted`,
                                description: ''
                            });
                        }
                    })

                    .catch((info) => {
                        ErrorNotification({
                            message: info.errorFields[0].errors[0],
                            description: ''
                        });
                    });
                break;
            case Service.ConcreteTestingTypes.AirContent:
                api.freshConcreteClient
                    .deleteAirContentTest(projectId, orderId, concreteLoadId, popupConfirmModalData.id, companyId)
                    .then((response) => {
                        if (response.isSuccess()) {
                            SuccessNotification({
                                message: t`Air Content test deleted successfully`,
                                description: ''
                            });
                            reloadTableData();
                            reloadDeliveryNotePage();
                        } else {
                            ErrorNotification({
                                message: t`Air Content test could not be deleted`,
                                description: ''
                            });
                        }
                    })

                    .catch((info) => {
                        ErrorNotification({
                            message: info.errorFields[0].errors[0],
                            description: ''
                        });
                    });
                break;
            case Service.ConcreteTestingTypes.WindSpeed:
                api.freshConcreteClientConcrete
                    .deleteWindSpeedTest(companyId, projectId, orderId, concreteLoadId, popupConfirmModalData.id)
                    .then((response) => {
                        if (response.isSuccess()) {
                            SuccessNotification({
                                message: t`Wind Speed test deleted successfully`,
                                description: ''
                            });
                            reloadTableData();
                            reloadDeliveryNotePage();
                        } else {
                            ErrorNotification({
                                message: t`Wind Speed test could not be deleted`,
                                description: ''
                            });
                        }
                    })

                    .catch((info) => {
                        ErrorNotification({
                            message: info.errorFields[0].errors[0],
                            description: ''
                        });
                    });
                break;
            case Service.ConcreteTestingTypes.RelativeHumidity:
                api.freshConcreteClientConcrete
                    .deleteRelativeHumidityTest(companyId, projectId, orderId, concreteLoadId, popupConfirmModalData.id)
                    .then((response) => {
                        if (response.isSuccess()) {
                            SuccessNotification({
                                message: t`Relative Humidity test deleted successfully`,
                                description: ''
                            });
                            reloadTableData();
                            reloadDeliveryNotePage();
                        } else {
                            ErrorNotification({
                                message: t`Relative Humidity test could not be deleted`,
                                description: ''
                            });
                        }
                    })

                    .catch((info) => {
                        ErrorNotification({
                            message: info.errorFields[0].errors[0],
                            description: ''
                        });
                    });
                break;
        }
    };

    const popupConfirmModal_onCancel = (): void => {
        setIsPopupConfirmModalVisible(false);
    };

    return (
        <>
            <Table
                data-testid="FreshConcreteTestsTable"
                showSorterTooltip={false}
                scroll={{ x: 1000 }}
                columns={freshConcreteTableColumns({ units, onEdit, onDelete, onDetails, marketId })}
                rowKey="id"
                dataSource={tableData}
                rowClassName="FreshConcreteTestsTable-testid"
            />

            {isTemperatureTestModalVisible && (
                <TemperatureTestModal
                    orderId={contextData.orderId}
                    concreteLoadId={contextData.concreteLoadId}
                    temperatureTestId={contextData?.id}
                    reloadFreshConcreteTests={reloadTableData}
                    open={isTemperatureTestModalVisible}
                    setOpen={setIsTemperatureTestModalVisible}
                    onOk={() => (enableReloadTableDataTimer.current = true)}
                    onCancel={() => (enableReloadTableDataTimer.current = true)}
                    initialFormValues={{
                        samplingDate: contextData?.samplingTime, // use samplingTime for testingDate
                        samplingTime: contextData?.samplingTime,
                        samplingLocation: contextData?.samplingLocation,
                        testingDate: contextData?.testingTime, // use testingTime for testingDate
                        testingTime: contextData?.testingTime,
                        airTemperature: contextData?.airTemprature?.quantity,
                        freshConcreteTemperature: contextData?.freshConcreteTemprature?.quantity,
                        externalInspection: contextData?.externalInspection
                    }}
                />
            )}

            {isConsistencyTestModalVisible && (
                <ConsistencyTestModal
                    orderId={contextData.orderId}
                    concreteLoadId={contextData.concreteLoadId}
                    consistencyTestId={contextData.id}
                    reloadFreshConcreteTests={reloadTableData}
                    open={isConsistencyTestModalVisible}
                    setOpen={setIsConsistencyTestModalVisible}
                    onOk={() => (enableReloadTableDataTimer.current = true)}
                    onCancel={() => (enableReloadTableDataTimer.current = true)}
                    initialFormValues={{
                        samplingDate: contextData?.samplingTime, // use samplingTime for testingDate
                        samplingTime: contextData?.samplingTime,
                        samplingLocation: contextData?.samplingLocation,
                        testingDate: contextData?.testingTime, // use testingTime for testingDate
                        testingTime: contextData?.testingTime,
                        flowTableTest: contextData?.diameter?.length,
                        externalInspection: contextData?.externalInspection
                    }}
                />
            )}

            {isAirContentTestModalVisible && (
                <AirContentTestModal
                    orderId={contextData.orderId}
                    concreteLoadId={contextData.concreteLoadId}
                    airContentTestId={contextData?.id}
                    reloadFreshConcreteTests={reloadTableData}
                    open={isAirContentTestModalVisible}
                    setOpen={setIsAirContentTestModalVisible}
                    onOk={() => (enableReloadTableDataTimer.current = true)}
                    onCancel={() => (enableReloadTableDataTimer.current = true)}
                    initialFormValues={{
                        samplingDate: contextData?.samplingTime, // use samplingTime for testingDate
                        samplingTime: contextData?.samplingTime,
                        samplingLocation: contextData?.samplingLocation,
                        testingDate: contextData?.testingTime, // use testingTime for testingDate
                        testingTime: contextData?.testingTime,
                        airContent: contextData?.airContent,
                        airMeasurementMethod: contextData?.airMeasurementMethod,
                        externalInspection: contextData?.externalInspection
                    }}
                />
            )}

            {isWindSpeedTestModalVisible && (
                <WindSpeedTestModal
                    orderId={contextData.orderId}
                    concreteLoadId={contextData.concreteLoadId}
                    windSpeedTestId={contextData?.id}
                    reloadFreshConcreteTests={reloadTableData}
                    open={isWindSpeedTestModalVisible}
                    setOpen={setIsWindSpeedTestModalVisible}
                    onOk={() => (enableReloadTableDataTimer.current = true)}
                    onCancel={() => (enableReloadTableDataTimer.current = true)}
                    initialFormValues={{
                        samplingDate: contextData?.samplingTime, // use samplingTime for testingDate
                        samplingTime: contextData?.samplingTime,
                        samplingLocation: contextData?.samplingLocation,
                        testingDate: contextData?.testingTime, // use testingTime for testingDate
                        testingTime: contextData?.testingTime,
                        windSpeed: contextData?.windSpeed,
                        externalInspection: contextData?.externalInspection
                    }}
                />
            )}

            {isRelativeHumidityTestModalVisible && (
                <RelativeHumidityTestModal
                    orderId={contextData.orderId}
                    concreteLoadId={contextData.concreteLoadId}
                    relativeHumidityTestId={contextData?.id}
                    reloadFreshConcreteTests={reloadTableData}
                    open={isRelativeHumidityTestModalVisible}
                    setOpen={setIsRelativeHumidityTestModalVisible}
                    onOk={() => (enableReloadTableDataTimer.current = true)}
                    onCancel={() => (enableReloadTableDataTimer.current = true)}
                    initialFormValues={{
                        samplingDate: contextData?.samplingTime, // use samplingTime for testingDate
                        samplingTime: contextData?.samplingTime,
                        samplingLocation: contextData?.samplingLocation,
                        testingDate: contextData?.testingTime, // use testingTime for testingDate
                        testingTime: contextData?.testingTime,
                        relativeHumidity: contextData?.relativeHumidity,
                        externalInspection: contextData?.externalInspection
                    }}
                />
            )}
            <Modal
                maskClosable={false}
                title={t`Delete test`}
                open={isPopupConfirmModalVisible}
                width={400}
                onOk={popupConfirmModalOnOk}
                onCancel={popupConfirmModal_onCancel}
                footer={[
                    <Button
                        data-testid="PopupConfirmModal.cancelButton"
                        key="cancelButton"
                        onClick={popupConfirmModal_onCancel}>
                        <Trans>Cancel</Trans>
                    </Button>,
                    <Button
                        data-testid="PopupConfirmModal.okButton"
                        key="okButton"
                        onClick={popupConfirmModalOnOk}
                        type="primary">
                        <Trans>OK</Trans>
                    </Button>
                ]}>
                <Trans>Are you sure with deleting this test?</Trans>
                {warningEvaporationMessage && (
                    <Alert
                        style={{ marginTop: '20px' }}
                        type="warning"
                        showIcon
                        description={t`By deleting this test, the evaporation rate can no longer be calculated. The existing
                        evaporation rate will no longer be available.`}
                    />
                )}
            </Modal>
        </>
    );
}

export default FreshConcreteTestsTable;

function determingTestingType(concreteTest: Service.FreshConcreteTest): Service.ConcreteTestingTypes {
    const test = { ...concreteTest };
    switch (concreteTest.testType) {
        case 'Temperature':
            return Service.ConcreteTestingTypes.Temperature;
        case 'Consistency':
            return Service.ConcreteTestingTypes.Consistency;
        case 'AirContent':
            return Service.ConcreteTestingTypes.AirContent;
        case 'WindSpeed':
            return Service.ConcreteTestingTypes.WindSpeed;
        case 'RelativeHumidity':
            return Service.ConcreteTestingTypes.RelativeHumidity;
        default:
            if (test.hasOwnProperty('airTemprature')) {
                // SMELL https://nexplore.atlassian.net/browse/NB-3996
                return Service.ConcreteTestingTypes.Temperature;
            }
            if (test.hasOwnProperty('diameter')) {
                // SMELL https://nexplore.atlassian.net/browse/NB-3996
                return Service.ConcreteTestingTypes.Consistency;
            }
            if (test.hasOwnProperty('airContent')) {
                return Service.ConcreteTestingTypes.AirContent;
            }
            if (test.hasOwnProperty('windSpeed')) {
                return Service.ConcreteTestingTypes.WindSpeed;
            }
            if (test.hasOwnProperty('relativeHumidity')) {
                return Service.ConcreteTestingTypes.RelativeHumidity;
            }
            return Service.ConcreteTestingTypes.Specimen;
    }
}

function wouldDeleteEvaporationRate(currentTestToDelete: FreshConcreteTest, tests: FreshConcreteTest[]): boolean {
    const currentType = determingTestingType(currentTestToDelete);
    const count = tests.map((x) => determingTestingType(x));
    const byType = {
        Temperature: count.filter((x) => x === 'Temperature').length,
        WindSpeed: count.filter((x) => x === 'WindSpeed').length,
        RelativeHumidity: count.filter((x) => x === 'RelativeHumidity').length
    };

    if (!(currentType in byType)) {
        return false;
    }

    if (byType[currentType as keyof typeof byType] !== 1) {
        return false;
    }

    const others = Object.entries(byType).filter(([key]) => key !== currentType);

    if (others.length !== 2) {
        return false;
    }

    if (others[0][1] >= 1 && others[1][1] >= 1) {
        return true;
    }

    return false;
}

/* eslint-disable react/require-default-props */

import { t } from '@lingui/macro';
import { Form, Modal } from 'antd';
import { useCallback, useEffect, useRef } from 'react';
import validateFields from 'src/app-react/components/Form/utils/validateFields';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import useProjectState from 'src/app-react/hooks/useProjectState';
import OrderLoadLiteLoadModalFooter from './components/Footer';
import OrderLoadLiteLoadModalForm from './components/Form';
import OrderLoadLiteLoadModalSteps from './components/Steps';
import useSaveOrderLoadLite from './hooks/useSaveOrderLoadLite';
import useSearchConcreteType from './hooks/useSearchConcreteType';
import useStepsPagination from './hooks/useStepsPagination';
import ConcreteLoadDataLiteFormFields from './models/ConcreteLoadDataLiteFormFields';

import {
    CONCRETE_LOAD_LITE_MODAL_ACTION,
    ConcreteLoadLiteModalAction,
    ORDER_LOAD_LITE_TYPES,
    OrderLoadLiteTypesValues
} from './models/Types';

import { ConcreteTypeExtendedBP } from '@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto';
import { OrderDeliveryNotes } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { ConcreteLoadDataLite } from '@nexploretechnology/concreting-core-client/concrete/delivery.note.lite-client/delivery.note.lite.dto';
import { MARKETS } from 'src/app-react/constants/markets';
import {
    fromConcreteTypeToFormFields,
    fromDeliveryTimeToFormFields,
    fromFormFieldsToSupplierConcreteLoadDataLite,
    fromOrderDeliveryNotesToFormFields,
    fromSupplierConcreteLoadDataLiteToConcreteLoadDataLiteFormFields
} from './utils/mappers';

interface OrderLoadLiteLoadProps {
    orderDeliveryNotes?: OrderDeliveryNotes;
    open: boolean;
    type?: OrderLoadLiteTypesValues;
    close: () => void;
    action?: ConcreteLoadLiteModalAction;
    concreteLoadDataLite?: ConcreteLoadDataLite;
    concreteLoadId?: string;
}

const SUCCESS_MESSAGE = (action: string) => {
    switch (action) {
        case CONCRETE_LOAD_LITE_MODAL_ACTION.CREATE:
            return t`A new load has been created`;
        case CONCRETE_LOAD_LITE_MODAL_ACTION.EDIT:
        default:
            return t`Load update successful`;
    }
};

const MODAL_TITLE = (action: string, type: string) => {
    if (action === CONCRETE_LOAD_LITE_MODAL_ACTION.CREATE && type === ORDER_LOAD_LITE_TYPES.QR) {
        return t`Add delivery note (QR)`;
    }
    if (action === CONCRETE_LOAD_LITE_MODAL_ACTION.CREATE && type === ORDER_LOAD_LITE_TYPES.MANUAL) {
        return t`Add manual delivery note`;
    }
    if (action === CONCRETE_LOAD_LITE_MODAL_ACTION.CREATE) {
        return t`Add delivery note`;
    }
    return t`Edit delivery note`;
};

const ERROR_MESSAGE = (action: string) => {
    switch (action) {
        case CONCRETE_LOAD_LITE_MODAL_ACTION.CREATE:
            return t`A new load could not be created`;
        case CONCRETE_LOAD_LITE_MODAL_ACTION.EDIT:
        default:
            return t`Load could not be updated`;
    }
};

const ERROR_DESCRIPTION = (code: string) => {
    switch (code) {
        case 'ERROR_CONCRETE_LOAD_LITE_DELIVERY_NOTE_NUMBER_ALREADY_REGISTERED':
            return t`DeliveryNoteNumber already registered`;
        case 'ERROR_CONCRETE_LOAD_LITE_DELIVERY_NOTE_NUMBER_CONCRETE_LOAD_ID_MISMATCH':
            return t`DeliveryNoteNumber already registered with supplierConcreteLoadId not matching the input supplierConcreteLoadId`;
        default:
            return t`unexpected error`;
    }
};

function OrderLoadLiteLoadModal({
    type,
    close,
    action,
    open,
    concreteLoadId,
    orderDeliveryNotes,
    concreteLoadDataLite: supplierConcreteLoadDataLite
}: OrderLoadLiteLoadProps) {
    const [form] = Form.useForm<Partial<ConcreteLoadDataLiteFormFields>>();
    const prefilledConcreteType = useRef<string>();
    const formOrigin = useRef<string>();
    const searchConcreteType = useSearchConcreteType();
    const { saveOrderLoadLite, editOrderLoadLite, error, success, isLoading } = useSaveOrderLoadLite();
    const { marketId } = useProjectState();
    const { page, maxPage, minPage, nextPage, prevPage } = useStepsPagination(type);

    useEffect(() => {
        if (error) {
            ErrorNotification({
                message: ERROR_MESSAGE(action),
                description: ERROR_DESCRIPTION(error.response?.data?.code)
            });
        }
    }, [action, error]);

    useEffect(() => {
        if (success) {
            SuccessNotification({
                message: SUCCESS_MESSAGE(action)
            });
            close();
        }
    }, [close, action, success]);

    const initializeForm = useCallback(
        (fieldsValue: Partial<ConcreteLoadDataLiteFormFields>, origin?: string) => {
            formOrigin.current = origin;

            form.setFieldsValue(fieldsValue);

            if (fieldsValue.number) {
                prefilledConcreteType.current = fieldsValue.number;

                if (type !== ORDER_LOAD_LITE_TYPES.QR) {
                    searchConcreteType(fieldsValue.number).then((items) => {
                        const concreteType = items.find(
                            (item) => item.concreteTypeNumber?.toLowerCase() === fieldsValue.number.toLowerCase()
                        );

                        if (concreteType) {
                            form.setFieldsValue(fromConcreteTypeToFormFields(concreteType, fieldsValue));
                        }
                    });
                }
            }
        },
        [form, searchConcreteType, type]
    );

    useEffect(() => {
        if (orderDeliveryNotes) {
            initializeForm(
                fromOrderDeliveryNotesToFormFields({
                    orderDeliveryNotes,
                    marketId
                })
            );
        }
    }, [initializeForm, orderDeliveryNotes, marketId]);

    useEffect(() => {
        if (supplierConcreteLoadDataLite) {
            initializeForm(
                fromSupplierConcreteLoadDataLiteToConcreteLoadDataLiteFormFields(supplierConcreteLoadDataLite)
            );
        }
    }, [initializeForm, supplierConcreteLoadDataLite]);

    const onSubmit = useCallback(() => {
        validateFields(form, () => {
            const fieldsValue = form.getFieldsValue(true);
            const params = {
                ...fromFormFieldsToSupplierConcreteLoadDataLite(fieldsValue),
                origin: (formOrigin.current ?? ORDER_LOAD_LITE_TYPES.MANUAL)?.toLowerCase()
            };

            if (action === CONCRETE_LOAD_LITE_MODAL_ACTION.CREATE) {
                saveOrderLoadLite(params);
            } else if (action === CONCRETE_LOAD_LITE_MODAL_ACTION.EDIT) {
                editOrderLoadLite(params, concreteLoadId);
            }
        });
    }, [form, action, saveOrderLoadLite, editOrderLoadLite, concreteLoadId]);

    const onNext = useCallback(() => {
        validateFields(form, () => {
            const { concreteType, ...fieldsValue } = form.getFieldsValue();

            if (fieldsValue.number && prefilledConcreteType.current !== fieldsValue.number) {
                const compressiveStrength = marketId === MARKETS.AUS ? form.getFieldValue('compressiveStrength') : null;
                const maxAggregateSize = marketId === MARKETS.AUS ? form.getFieldValue('maxAggregateSize') : null;

                form.setFieldsValue(
                    fromConcreteTypeToFormFields(concreteType || ({} as ConcreteTypeExtendedBP), {
                        maxAggregateSize,
                        compressiveStrength
                    })
                );
                prefilledConcreteType.current = fieldsValue.number;
            }

            if (fieldsValue.deliveryTime) {
                // initiazlie timestams, (we check agains unregistered fields too).
                form.setFieldsValue(
                    fromDeliveryTimeToFormFields(fieldsValue.deliveryTime, form.getFieldsValue(true), marketId)
                );
            }

            nextPage();
        });
    }, [form, nextPage, marketId]);

    return (
        <Modal
            open={open}
            onCancel={close}
            maskClosable={false}
            width="80vw"
            title={MODAL_TITLE(action, type)}
            style={{ maxWidth: 1000 }}
            footer={
                <OrderLoadLiteLoadModalFooter
                    page={page}
                    maxPage={maxPage}
                    minPage={minPage}
                    onSubmit={onSubmit}
                    onNext={onNext}
                    onPrev={prevPage}
                    onClose={close}
                    disabled={isLoading}
                />
            }>
            <OrderLoadLiteLoadModalSteps type={type} page={page} />
            <br /> <br />
            <Form layout="vertical" autoComplete="off" form={form}>
                <OrderLoadLiteLoadModalForm
                    type={type}
                    action={action}
                    initializeForm={initializeForm}
                    onNext={onNext}
                    page={page}
                    origin={supplierConcreteLoadDataLite?.origin as OrderLoadLiteTypesValues}
                />
            </Form>
        </Modal>
    );
}

OrderLoadLiteLoadModal.defaultProps = {
    type: ORDER_LOAD_LITE_TYPES.MANUAL,
    action: CONCRETE_LOAD_LITE_MODAL_ACTION.CREATE
};

export default OrderLoadLiteLoadModal;

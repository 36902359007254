/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */

import { t, Trans } from '@lingui/macro';
import { Input } from 'antd';
import InputFormItem, { InputFormItemProps } from 'src/app-react/components/Form/FormItem/InputFormItem';

function LocationNameFormItem(props: InputFormItemProps) {
    return (
        <InputFormItem
            data-testid="locationNameFormItem"
            label={<Trans>Location Name</Trans>}
            name="locationName"
            required
            {...props}>
            <Input type="text" placeholder={t`Enter a location name`} data-testid="locationName" />
        </InputFormItem>
    );
}

export default LocationNameFormItem;

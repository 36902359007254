import { Trans } from '@lingui/macro';
import { Layout } from 'antd';
import useNavigateDiscardChanges from 'src/app-react/hooks/useNavigateDiscardChanges';
import logoNexplore from '../../../assets/images/nexplore-logo.svg';
import FeedbackButton from '../FeedbackButton/FeedbackButton';
import RawLinkButton from '../RawLinkButton/RawLinkButton';
import styles from './AppMenu.module.css';
import SiderMenu from './components/SiderMenu';
import TopMenu from './components/TopMenu';
import { UserIsProjectManagerProvider } from 'src/app-react/providers/UserIsProjectManagerContext';

function AppMenu({ children }: Readonly<{ children: React.ReactNode }>) {
    const navigateConfirm = useNavigateDiscardChanges();

    return (
        <Layout className={styles.menu}>
            <Layout.Header className={styles.menuHeader}>
                <div className={styles.menuTitle}>
                    <div className={styles.menuLogo}>
                        <img alt="logo" src={logoNexplore} />
                    </div>
                    <div className={styles.menuName}>
                        <RawLinkButton data-testid="titleMenuBtn" onClick={() => navigateConfirm(`/projects`)}>
                            <Trans>Concrete</Trans>
                        </RawLinkButton>
                    </div>
                </div>

                <div className={styles.menuTitle}>
                    <FeedbackButton />
                    <TopMenu />
                </div>
            </Layout.Header>
            <Layout>
                <UserIsProjectManagerProvider>
                    <SiderMenu />
                </UserIsProjectManagerProvider>

                <Layout.Content className={styles.menuContent}>{children}</Layout.Content>
            </Layout>
        </Layout>
    );
}

export default AppMenu;

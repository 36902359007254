import { t, Trans } from '@lingui/macro';
import { Select } from 'antd';
import debounce from 'lodash.debounce';
import { useCallback, useContext, useEffect } from 'react';
import useGetSupplierProducedConcreteNumbersByProject from 'src/app-react/business-page/shared/Modals/OrderLiteModal/hooks/useGetSupplierProducedConcreteNumbersByProject';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import { DeliveryDahsboardPageContext } from '../../../context/DeliveryDahsboardPageContext';
import { DEBOUNCE_TIME } from '../../DeliveryDashboardTableViews';
import useLocalStorage from 'src/app-react/hooks/useLocalStorage';

export function ConcreteTypeInputItem() {
    const { filter, setFilter } = useContext(DeliveryDahsboardPageContext);
    const [localStorageDeliveryFilter, setLocalStorageDeliveryFilter] = useLocalStorage('deliveryFilter');
    const { loadData, error, typeNumbers } = useGetSupplierProducedConcreteNumbersByProject();

    function onChange(value: string[]) {
        const newFilter = { ...filter, concreteTypeNumbers: value };
        setFilter(newFilter);
        setLocalStorageDeliveryFilter(newFilter);
    }

    const loadConcreteTypeNumbers = useCallback(() => {
        const debouncedLoadData = debounce(() => {
            loadData(filter);
        }, DEBOUNCE_TIME);

        debouncedLoadData();
    }, [filter, loadData]);

    useEffect(() => {
        if (error) {
            ErrorNotification({
                message: error.message || 'unexpected error',
                description: error.description
            });
        }
    }, [error]);

    useEffect(() => {
        loadConcreteTypeNumbers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    return (
        <>
            <b>
                <Trans>Concrete types</Trans>
            </b>

            <Select
                data-testid="concretetypes"
                mode="multiple"
                allowClear
                // eslint-disable-next-line react/jsx-no-bind
                onChange={onChange}
                value={filter?.concreteTypeNumbers}
                placeholder={t`Select concrete types`}
                style={{ width: '100%' }}
                options={typeNumbers}
            />
        </>
    );
}

import { t, Trans } from '@lingui/macro';
import { Select } from 'antd';
import debounce from 'lodash.debounce';
import { useCallback, useContext, useEffect } from 'react';
import useGetSupplierOrderIdsByProject from 'src/app-react/business-page/shared/Modals/OrderLiteModal/hooks/useGetSupplierOrderIdsByProject';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import { MARKETS } from 'src/app-react/constants/markets';
import useLocalStorage from 'src/app-react/hooks/useLocalStorage';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { DeliveryDahsboardPageContext } from '../../../context/DeliveryDahsboardPageContext';
import { DEBOUNCE_TIME } from '../../DeliveryDashboardTableViews';

export function SupplierOrderIdInputItem() {
    const { marketId } = useProjectState();
    const { filter, setFilter } = useContext(DeliveryDahsboardPageContext);
    const [localStorageDeliveryFilter, setLocalStorageDeliveryFilter] = useLocalStorage('deliveryFilter');
    const { loadData, error, supplierNumberIds } = useGetSupplierOrderIdsByProject();

    function onChange(value: string[]) {
        const newFilter = { ...filter, supplierOrderIds: value };
        setFilter(newFilter);
        setLocalStorageDeliveryFilter(newFilter);
    }

    const loadSupplierOrderIds = useCallback(() => {
        const debouncedLoadData = debounce(() => {
            loadData(undefined, filter);
        }, DEBOUNCE_TIME);

        debouncedLoadData();
    }, [filter, loadData]);

    useEffect(() => {
        if (error) {
            ErrorNotification({
                message: error.message || 'unexpected error',
                description: error.description
            });
        }
    }, [error]);

    useEffect(() => {
        loadSupplierOrderIds();
    }, [filter, loadSupplierOrderIds]);

    return (
        <>
            {marketId !== 'AUS' ? (
                <b>
                    <Trans>Supplier Order Ids</Trans>
                </b>
            ) : (
                <b>
                    <Trans>Supplier Order / Job Number</Trans>
                </b>
            )}

            <Select
                data-testid="supplierorderids"
                mode="multiple"
                allowClear
                // eslint-disable-next-line react/jsx-no-bind
                onChange={onChange}
                value={filter?.supplierOrderIds}
                placeholder={
                    marketId !== MARKETS.AUS ? t`Select supplier order ids` : t`Select Supplier Order / Job Number`
                }
                style={{ width: '100%' }}
                options={supplierNumberIds}
            />
        </>
    );
}

import { t, Trans } from '@lingui/macro';
import { Market } from '@nexploretechnology/concreting-core-client';
import { Button, Checkbox, Col, Form, Input, Modal, Row, Switch } from 'antd';
import { useEffect } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import useApi from 'src/app-react/hooks/useApi';

export type TProjectRoles = ['ProjectManager', 'Foreman', 'ConcreteEngineer', 'Subcontractor', 'Batcher'];

export interface IUserCreationPayloadFields {
    projectRoles: TProjectRoles;
    user: {
        name: string;
        userEmail: string;
        userId: '';
        isUserIntern: boolean;
    };
}

interface IManageUsersFormProps {
    projectId?: string;
    companyId: string;
    marketId: Market;
    hideModal: () => void;
    showModal: boolean;
    onComplete?: () => void;
}

function ManageUsersForm(props: IManageUsersFormProps) {
    const { companyId, projectId, marketId, hideModal, showModal, onComplete } = props;
    const [manageUsersForm] = Form.useForm();
    const api = useApi();

    useEffect(() => {
        manageUsersForm.setFieldsValue({ isUserIntern: marketId === 'AUS' });
    }, [manageUsersForm, marketId]);

    const cleanForm = () => {
        manageUsersForm.setFieldsValue({
            name: '',
            projectRoles: null,
            secondName: '',
            userEmail: '',
            isUserIntern: marketId === 'AUS'
        });
    };

    const onSubmit = () => {
        if (!projectId) {
            ErrorNotification({
                message: t`Project Id not available`,
                description: t`Please, make sure that you have an active project`
            });
            return;
        }

        manageUsersForm
            .validateFields()
            .then(() => {
                const formFields = manageUsersForm.getFieldsValue();
                const payload: IUserCreationPayloadFields = {
                    user: {
                        name: `${formFields.name} ${formFields.secondName}`,
                        userEmail: formFields.userEmail,
                        userId: '',
                        isUserIntern: formFields.isUserIntern
                    },
                    projectRoles: formFields.projectRoles
                };
                api.projectManagementClient
                    .addProjectMember(companyId, projectId, payload)
                    .then((response) => {
                        if (response.isSuccess()) {
                            SuccessNotification({ message: t`Invitation sent` });
                            hideModal();
                            cleanForm();
                            onComplete?.();
                        } else {
                            ErrorNotification({ message: response.getError(), description: '' });
                        }
                    })
                    .catch((info: any) => {
                        if (info.response.data.statusCode === 409) {
                            ErrorNotification({
                                message: t`This user is already assigned to this project`,
                                description: ''
                            });
                        }
                    });
            })
            .catch(() =>
                ErrorNotification({
                    message: t`Form not filled correctly`,
                    description: t`Please, make sure that all the fields are filled correctly`
                })
            );
    };

    return (
        <Modal
            maskClosable={false}
            title={t`Invite user`}
            open={!!showModal}
            onCancel={() => {
                hideModal();
                cleanForm();
            }}
            onOk={onSubmit}
            data-testid="manager-user-form"
            forceRender
            footer={[
                <Button
                    onClick={() => {
                        hideModal();
                        cleanForm();
                    }}
                    key="hide">
                    <Trans>Cancel</Trans>
                </Button>,
                <Button type="primary" onClick={onSubmit} key="show">
                    <Trans>SEND INVITE</Trans>
                </Button>
            ]}>
            <Col span={24}>
                <Form form={manageUsersForm} data-testid="manageUsersForm" id="manageUsersForm" layout="vertical">
                    <Form.Item
                        name="isUserIntern"
                        hidden={marketId !== 'AUS'}
                        valuePropName="checked"
                        label={t`Intern User`}>
                        <Switch data-testid="isUserIntern" />
                    </Form.Item>

                    <Form.Item
                        label={
                            <span>
                                <Trans>First Name</Trans>
                            </span>
                        }
                        name="name"
                        rules={[{ required: true, message: t`This field is required` }]}>
                        <Input type="text" placeholder={t`Enter first name`} data-testid="name" />
                    </Form.Item>

                    <Form.Item
                        label={
                            <span>
                                <Trans>Second Name</Trans>
                            </span>
                        }
                        name="secondName"
                        rules={[{ required: true, message: t`This field is required` }]}>
                        <Input type="text" placeholder={t`Enter Second name`} data-testid="second_name" />
                    </Form.Item>
                    <Form.Item
                        label={
                            <span>
                                <Trans>User Email</Trans>
                            </span>
                        }
                        name="userEmail"
                        rules={[
                            { required: true, message: t`This field is required` },
                            {
                                type: 'email',
                                message: t`Please input a correct email address`
                            }
                        ]}>
                        <Input type="email" placeholder={t`Enter email address`} data-testid="email" />
                    </Form.Item>

                    <Form.Item
                        label={
                            <span>
                                <Trans>Select Roles</Trans>
                            </span>
                        }
                        name="projectRoles"
                        rules={[{ required: true, message: t`This field is required` }]}>
                        <Checkbox.Group>
                            <Row>
                                <Checkbox value="ProjectManager" data-testid="project_manager">
                                    <Trans>Project Manager</Trans>
                                </Checkbox>
                            </Row>
                            <Row>
                                <Checkbox value="Foreman" data-testid="foreman">
                                    <Trans>Foreman</Trans>
                                </Checkbox>
                            </Row>
                            <Row hidden={marketId === 'AUS'}>
                                <Checkbox value="ConcreteEngineer" data-testid="concrete_engineer">
                                    <Trans>Concrete Engineer</Trans>
                                </Checkbox>
                            </Row>
                            <Row hidden={marketId !== 'AUS'}>
                                <Checkbox value="Subcontractor" data-testid="subcontractor">
                                    <Trans>Subcontractor</Trans>
                                </Checkbox>
                            </Row>
                            <Row hidden={marketId === 'AUS'}>
                                <Checkbox value="Batcher" data-testid="batcher">
                                    <Trans>Batcher</Trans>
                                </Checkbox>
                            </Row>
                            <Row>
                                <Checkbox value="Viewer" data-testid="viewer">
                                    <Trans>Viewer</Trans>
                                </Checkbox>
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Form>
            </Col>
        </Modal>
    );
}

export default ManageUsersForm;

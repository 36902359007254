import { Trans } from '@lingui/macro';
import { Radio, RadioChangeEvent, Row, Col } from 'antd';
import { useContext } from 'react';
import { DeliveryDahsboardPageContext } from '../../../context/DeliveryDahsboardPageContext';
import useLocalStorage from 'src/app-react/hooks/useLocalStorage';
import moment from 'moment';

export function PeriodConcretingInputItem() {
    const { filter, setFilter } = useContext(DeliveryDahsboardPageContext);
    const [localStorageDeliveryFilter, setLocalStorageDeliveryFilter] = useLocalStorage('deliveryFilter');

    function onChange(e: RadioChangeEvent) {
        const period = e.target.value;
        const periodOfConcreting = getPeriodOfConcreting(period);
        const newFilter = { ...filter, period, periodOfConcreting };
        setFilter(newFilter);
        setLocalStorageDeliveryFilter(newFilter);
    }

    return (
        <>
            <b>
                <Trans>Period of concreting</Trans>
            </b>
            <Row>
                <Col>
                    <Radio.Group
                        value={filter?.period}
                        // eslint-disable-next-line react/jsx-no-bind
                        data-testid="period-concreting-radio-group"
                        onChange={onChange}>
                        <Radio.Button value="today">
                            <Trans>Today</Trans>
                        </Radio.Button>
                        <Radio.Button value="last2Days">
                            <Trans>Last 2 days</Trans>
                        </Radio.Button>
                        <Radio.Button value="lastWeek">
                            <Trans>Last week</Trans>
                        </Radio.Button>
                        <Radio.Button value="custom">
                            <Trans>Custom</Trans>
                        </Radio.Button>
                        <Radio.Button value="allTime">
                            <Trans>All time</Trans>
                        </Radio.Button>
                    </Radio.Group>
                </Col>
            </Row>
        </>
    );
}

function getPeriodOfConcreting(period: string) {
    switch (period) {
        case 'last2Days':
            return {
                startDate: moment().add(-2, 'days').toDate(),
                endDate: moment().toDate()
            };
        case 'lastWeek':
            return {
                startDate: moment().add(-7, 'days').toDate(),
                endDate: moment().toDate()
            };
        case 'custom':
        case 'today':
            return {
                startDate: moment().startOf('day').toDate(),
                endDate: moment().endOf('day').toDate()
            };
        case 'allTime':
        default:
            return null;
    }
}

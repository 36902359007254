import { t } from '@lingui/macro';
import { Space } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import AppHeader from 'src/app-react/components/AppHeader/AppHeader';
import { useSearchParamsState } from 'src/app-react/hooks/useSearchParamsState';
import { DeliveryDashboardAmount } from './components/DeliveryDashboardAmount';
import DeliveryDashboardBreadcrumb from './components/DeliveryDashboardBreadcrumb';
import { DeliveryDashboardFilters } from './components/DeliveryDashboardFilters';
import { DeliveryDashboardTableViews } from './components/DeliveryDashboardTableViews';
import { DeliveryDahsboardPageContext, DeliveryDashboardFilter } from './context/DeliveryDahsboardPageContext';
import useLocalStorage from 'src/app-react/hooks/useLocalStorage';
import moment from 'moment';

export function DeliveryDashboardPage() {
    const navigate: NavigateFunction = useNavigate();
    const [localStorageDeliveryFilter, setLocalStorageDeliveryFilter] = useLocalStorage('deliveryFilter');

    const [filter, setFilter] = useSearchParamsState<DeliveryDashboardFilter>('filter', localStorageDeliveryFilter);

    const [refreshData, setRefreshData] = useState<boolean>(false);

    useEffect(() => {
        const deliveryDashboardDefaultFilter = {
            period: 'today',
            periodOfConcreting: {
                startDate: moment().startOf('day').toDate(),
                endDate: moment().endOf('day').toDate()
            }
        };
        if (!filter) {
            setFilter(deliveryDashboardDefaultFilter);
            setLocalStorageDeliveryFilter(deliveryDashboardDefaultFilter);
        }
    }, []);

    const deliveryDahsboardPageContextValue = useMemo(
        () => ({
            filter,
            setFilter
        }),
        [filter, setFilter]
    );

    return (
        <>
            <AppHeader
                title={t`Deliveries`}
                onBack={() => navigate('/projects')}
                breadcrumb={<DeliveryDashboardBreadcrumb />}
            />

            <DeliveryDahsboardPageContext.Provider value={deliveryDahsboardPageContextValue}>
                <Space size="large" direction="vertical" style={{ width: '100%' }}>
                    <DeliveryDashboardFilters />
                    <DeliveryDashboardAmount refreshData={refreshData} />
                    <DeliveryDashboardTableViews refreshData={refreshData} setRefreshData={setRefreshData} />
                </Space>
            </DeliveryDahsboardPageContext.Provider>
        </>
    );
}
